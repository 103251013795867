.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1009px) {
  div[white-cell="true"] {
    display: none;
  }
}

#logsToolbar {
  border: 0px;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 10px;
}

#logsToolbar > div.right {
  flex-grow: 1;
}
